import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Formik, Field, Form } from 'formik';
import { color, formStyles } from '../../../../utils/style';
import * as Yup from 'yup';
import IcomoonIcon from '../../../shared/IcomoonIcon';


const Wrapper = styled('div')`
  ${formStyles}
  .button {
    width: 100%;
  }
  .submitting {
    .labelField {
      opacity: 0.5;
    }
  }
  .form-inputs {
    max-width: 350px;
    margin: 0 auto;
    .errorMsg {
      background: ${color.red};
      color: ${color.white};
      padding: 10px 20px;
      border-radius: 5px;
      margin-bottom: 20px;
      text-align: center;
      display: none;
      &.visible {
        display: block;
      }
    }
    .visible ~ .labelField {
      opacity: 1;
    }
  }
  .successMsg {
    &.visible {
      & + .form-inputs {
        display: none;
      }
    }
    padding: 30px;
    z-index: 1;
  }
  
  .successMsgText {
    p {
      margin-top: 30px;
      font-size: 2rem;
    }
    .small {
      font-size: 16px;
      margin-top: 20px;
    }
  }
  
  
  .button-wrapper {
    text-align: center;
  }
  .back-button {
    border-color: transparent !important;
    margin-top: 20px;
    span {margin-left: 7px;}
    &:hover, &:active {
      background-color: transparent !important;
      border-color: ${color.white} !important;
      span {
        color: ${color.white} !important;
      }
    }
  }
`;

const withdrawPaymentApiUrl = process.env.WITHDRAW_API2_STEP2_URL;


export default class WithdrawPaymentData extends Component {

  showSuccessMsg = () => {
    this.hideMsg();
    const successMsg = document.getElementById('successMsgBox');
    successMsg.classList.add('visible');
  };

  hideMsg = () => {
    const successMsg = document.getElementById('successMsgBox');
    successMsg.classList.remove('visible');
    const errorMsg = document.getElementById('errorMsg');
    errorMsg.classList.remove('visible');
  };

  showErrorMsg = (msg) => {
    const errorMsg = document.getElementById('errorMsg');
    errorMsg.innerText = msg;
    errorMsg.classList.add('visible');
  };

  submitFormHandler = (values, formApi) => {
    let valuesObject = Object.assign({}, values);
    valuesObject.amount = +values.amount;
    valuesObject.remarks = 'From WD form. ' + values.remarks;
    console.log('Data going to Withdraw API: ');
    console.log(valuesObject);

    let body = JSON.stringify(valuesObject);

    this.hideMsg();

    fetch(withdrawPaymentApiUrl, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.token,
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: body
    }).then(response => {
      const status = response.status;
      const data = response.json();
      return data.then((res) => {
        return {status, json: res}
      })
    })
      .then(({status, json}) => {
        //console.log('Step 2 answer from the API:');
        //console.log(json);
        if (status === 401) {
          this.showErrorMsg('ขออภัยชื่อผู้ใช้หรือบัญชีธนาคารนี้ไม่ถูกต้องหรือไม่มีอยู่ กรุณาใส่รายละเอียดที่ถูกต้อง');
          formApi.setSubmitting(false);
        } else if (status === 400) {
          this.showErrorMsg('มีบางอย่างผิดพลาด โปรดลองอีกครั้งในภายหลังหรือติดต่อคอลเซ็นเตอร์ของเรา');
          formApi.setSubmitting(false);
        } else if (status === 500) {
          this.showErrorMsg('บัญชีอยู่ระหว่างดำเนินกระบวนการ กรุณารอสักครู่ หากจำนวนเงินของท่านไม่ปรากฎใน 5 นาที โปรดติดต่อฝ่ายดูแลลูกค้า');
          formApi.setSubmitting(false);
        } else if (status === 200) {
          this.showSuccessMsg();
          formApi.setSubmitting(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const formData = this.props.formData;
    console.log('Form Data');
    console.log(formData);

    const initialValues = {
      amount: 500,
      channelId: '8',
      custBankAccountId: '',
      remarks: '',
    };

    const defaultOption = <option key="default" defaultValue={true}>เลือกที่นี่</option>;


    const customerBankAccounts = formData.customerBankAccountsDropDown.results.map(i =>
      <option key={i.id} value={i.id}> {i.text} </option>
    );
    const customBankAccountOptions = [defaultOption, ...customerBankAccounts];

    return (
      <Wrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={this.submitFormHandler}
          validationSchema={Yup.object().shape({
            amount: Yup.number().typeError('จำนวนที่ต้องการ')
              .min(1, 'Enter 1 or more').required('จำนวนที่ต้องการ'),
            custBankAccountId: Yup.string().required('จำเป็นต้องใช้'),
          })}

          render={({ errors, status, touched, isSubmitting, isValid, values }) => (
            <Form id="sendUserPaymentForm">
              <Field type="hidden" name="channelId" value={values.channelId || ''}/>
              <div id="successMsgBox" className="successMsg">
                <IcomoonIcon icon="check" color="#ffcdcd" size={46}/>
                <div className="successMsgText">
                  <p>ส่งคำขอถอนเงินเรียบร้อย กรุณารอสักครู่ขณะทางเราดำเนินกระบวนการให้กับท่าน หากจำนวนเงินของท่านไม่ปรากฎใน 10 นาที โปรดติดต่อฝ่ายดูแลลูกค้า</p>
                  <p className="small">
                    แอด <a target="_blank" href="https://lin.ee/nEuGE0U">LineID @LNWASIA</a> เพื่อไม่พลาดการติดต่อกับเรา
                  </p>
                </div>
              </div>
              <div className="form-inputs">
                <div id="errorMsg" className="errorMsg">There has been an error!</div>

                <div className="all-fields">
                  <div className="labelField">
                    <label htmlFor="withdraw-amount">จำนวนเงินถอน</label>
                    <div className="fieldWrap iconField">
                      <span className="icon">บาท</span>
                      {errors.amount && touched.amount && <div className="error-new">{errors.amount}</div>}
                      <Field type="text" name="amount" placeholder="0.00" id="withdraw-amount"
                             value={values.amount || ''}/>
                    </div>
                  </div>

                  <div className="labelField">
                    <label htmlFor="withdraw-channel">
                      เลขที่บัญชีของคุณ
                    </label>
                    <div className="fieldWrap iconField">
                      <IcomoonIcon icon="arrow-down-simple" color={color.white} size={18}/>
                      {errors.custBankAccountId && touched.custBankAccountId &&
                      <div className="error-new">{errors.custBankAccountId}</div>
                      }
                      <Field name="custBankAccountId" component="select"
                             placeholder="Player Bank Account" id="withdraw-account">
                        {customBankAccountOptions}
                      </Field>
                    </div>
                  </div>

                  <div className="labelField">
                    <label htmlFor="withdraw-remarks">หมายเหตุ</label>
                    <div className="fieldWrap iconField">
                      {errors.remarks && touched.remarks && <div className="error-new">{errors.remarks}</div>}
                      <Field component="textarea" name="remarks" placeholder="โปรดระบุ (ถ้ามี)" id="withdraw-remarks"
                             value={values.remarks || ''}/>
                    </div>
                  </div>
                </div>

                <div className="button-wrapper">
                  {status && status.msg && <div>{status.msg}</div>}
                  <button aria-label="เข้าสู่ระบบ" className="button gradient-button" type="submit"
                          disabled={(!isValid || isSubmitting)}>
                    <span>ส่งข้อมูล</span>
                  </button>
                  <button aria-label="Back" className="button transparent-button back-button" onClick={this.props.switchVisual}>
                    <IcomoonIcon icon="arrow-left-simple" color={color.white} size={18}/>
                    <span>ย้อนกลับ</span>
                  </button>
                </div>
              </div>
            </Form>
          )}
        />
      </Wrapper>

    );
  }
}

WithdrawPaymentData.propTypes = {
  switchView: PropTypes.func,
  switchVisual: PropTypes.func,
  formData: PropTypes.object
};